import React, {useState} from "react";
import styled from "styled-components"
import Button from "./atoms/Button";
import NftCard from "./atoms/NftCard";
import Title from "./atoms/Title";
import FormModal from "./atoms/FormModal";

const StyledWrapper = styled.div`
  .error {
    width: 50%;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }

  .grid {
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-gap: 40px;
  }
`

const RaptorWrapper = ({userWallet, userRaptor}) => {
    const [activeId, setActiveId] = useState(null)
    const [activeKalaoId, setActiveKalaoId] = useState(null)

    const Content = userRaptor.length > 0 ?
        <div className="grid">
            {
                userRaptor.map((i) => (
                    <NftCard key={i.id}
                        img={i.image}
                        id={i.id}
                        idOfKalao={i.idOfKalao}
                        openForm={() => {
                            console.log("ID Claim : " + i.id)
                            setActiveKalaoId(i.idOfKalao);
                            setActiveId(i.id+1)
                        }}
                        userWallet={userWallet}
                    />
                    )
                )
            }
        </div>
        :
        <div className="error">
            <Title>No Raptor were found on this wallet</Title>
            <Button target="https://marketplace.kalao.io/collection/0x9f98ccebdb3138c0ebfbdc9b9b94e2683bdaf1fb">Buy a
                Raptor</Button>
        </div>

    const Modal = activeId ?
        <FormModal
            closeModal={() => {
                setActiveId(null);
                setActiveKalaoId(null);
            }}
            id={activeId-1}
            idOfKalao={activeKalaoId}
            userWallet={userWallet}
        />
        :
        <></>

    return (
        <StyledWrapper>
            {Modal}
            {Content}
        </StyledWrapper>
    )
}

export default RaptorWrapper
