import React from "react";
import styled from "styled-components"
import Button from "./Button";
import Title from "./Title";
import {teamWallet} from "../../functions/interact_sc";

const StyledCard = styled.div`
background: rgba(0,0,0,0.5);
border-radius:10px;
overflow: hidden;
cursor:pointer;
.img-wrapper{
    overflow:hidden;
    img{
        width:100%;
        transition:all 0.4s;
    }
}
.content{
    padding:20px;
}
.coin-type{
    color:white;
    font-family:MontReg;
    font-size:12px;
    margin-bottom:10px;
    display:block;
}
&:hover{
    img{
        transform:scale(1.2);
        transition:all 0.4s;
    }
}
`

const NftCard = ({img,id, idOfKalao,coinType,openForm, userWallet}) => {

    return (
        <StyledCard onClick={openForm}>
            <div className="img-wrapper">
                <img src={img} alt=""/>
            </div>
            <div className="content">
                {
                    userWallet.toLowerCase() === teamWallet.toLowerCase() ?
                        <>
                            <Title>Raptor Origin Z #{idOfKalao}</Title>
                            <span className="coin-type">blockchain id : #{id}</span>
                            <Button click={openForm}>Send coin and burn</Button>

                        </>
                        :
                        <>
                        <Title>Raptor Origin Z #{idOfKalao}</Title>
                            <span className="coin-type">blockchain id : #{id}</span>
                            <Button click={openForm}>Claim</Button>
                        </>
                        }
            </div>
        </StyledCard>
    )
}

export default NftCard
