import React from "react";
import styled from "styled-components"

const StyledButton = styled.a`
    cursor:pointer;
    display:block;
    padding:10px 20px;
    border-radius:5px;
    color:#161527;
    background:white;
    font-family:MontReg;
    text-decoration:none;
    transition:background 0.2s;

    &:hover{
        background:#a19ec8;
        transition:background 0.2s;
    }
`

const Button = ({click,children,target}) => {

    return (
        <StyledButton onClick={click ? click : null} href={target ? target : "#"}>
            {children}
        </StyledButton>
    )
}

export default Button