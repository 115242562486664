import { useState, useEffect } from 'react';
import Title from './components/atoms/Title';
import Container from './components/atoms/Container';
import Header from "./components/Header"
import Login from './components/Login';
import RaptorWrapper from './components/RaptorWrapper';
import './css/App.css';
import './css/fonts.css';
import {balanceOf, getJsonMetadata, tokenOfOwnerByIndex, tokenURI, teamWallet, ethereum} from "./functions/interact_sc";
import {checkIfWalletIsConnected} from "./functions/login";

function App() {
  const [userWallet, setUserWallet] = useState("")
  const [userRaptor, setUserRaptor] = useState([])

  useEffect(() => {
    setUserRaptor([]);
    if (userWallet.length > 0) {
      balanceOf(userWallet).then((numberOfUsersNFT) => {
        for (let i = 0; i < numberOfUsersNFT; i++) {
          tokenOfOwnerByIndex(userWallet, i).then((tokenId) => {
            tokenURI(tokenId).then((ipfsURI) => {
              getJsonMetadata(ipfsURI).then(metadata => {
                const idOfKalao = metadata['edition'];
                const idOfBlockchain = parseInt(tokenId);
                setUserRaptor(prevArray => [...prevArray, {name: metadata.name, image: metadata.image, id: idOfBlockchain, idOfKalao: idOfKalao}]);
              });
            })
          });
        }
      })
    }
  },[userWallet])

  useEffect(() => {
    checkIfWalletIsConnected().then(res => {
      if (res !== undefined) {
        setUserWallet(res);
      } else {
        setUserWallet('');
      }
    });
  }, []);

  try {
    ethereum.on('accountsChanged', function (accounts) {
      setUserWallet(accounts[0]);
    });
  } catch {}


  return (
    <>
      <Header userWallet={userWallet} setUserWallet={setUserWallet} />
      <Container>
        {
          userWallet.toLowerCase() === teamWallet.toLowerCase() ?
              <Title tall center>View users requests</Title>
              :
              <Title tall center>Claim your coins</Title>
        }
        <Login userWallet={userWallet} setUserWallet={setUserWallet}>
          <RaptorWrapper userWallet ={userWallet} userRaptor={userRaptor} />
        </Login>
      </Container>
    </>
  );
}

export default App;
