import React from "react";
import styled from "styled-components"
import Button from "./atoms/Button";
import Title from "./atoms/Title";
import {connectWallet} from "../functions/login";

const StyledLogin = styled.div`
width:50%;
margin:0 auto;
padding:40px;
border-radius:10px;
background: rgba(0,0,0,0.5);
`

const Login = ({children,userWallet, setUserWallet}) => {

    const handleLogin = () => {
        connectWallet().then(res => {
            setUserWallet(res);
        })
    }

    let Content = userWallet.length>0 ?
    children
    :
    <StyledLogin>
        <Title>Your wallet is not connected</Title>
        <Button click={handleLogin}>Connect Wallet</Button>
    </StyledLogin>

    return(
        Content
    )
}

export default Login
