import React from "react";
import styled from "styled-components"

const StyledTitle = styled.div`
color:white;
font-family:MontBold;
margin-bottom:20px;
font-size: ${props=>props.tall ? "32px" : "14px"};
text-align: ${props=>props.center ? "center" : "left"};
`

const Title = ({children,tall,center}) => {

    return (
        <StyledTitle tall={tall} center={center}>
            {children}
        </StyledTitle>
    )
}

export default Title