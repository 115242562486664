import React from "react";
import styled from "styled-components"
import headerLogo from "../images/logo.png"
import Button from "./atoms/Button";
import {connectWallet} from "../functions/login";

const StyledHeader = styled.header`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:20px 40px;
    background: rgba(0,0,0,0.3);
    margin-bottom:40px;
    .header-logo{
        height:150px;
    }
`

const Header = ({userWallet,setUserWallet}) => {

    const handleLogin = () => {
        connectWallet().then(res => {
            console.log(res);
            setUserWallet(res);
        })
    }

    let AccountInfos = userWallet.length>0 ?
    <Button target={"https://snowtrace.io/address/"+userWallet}>{userWallet}</Button>
    :
    <Button click={handleLogin}>Connect wallet</Button>

    return (
        <StyledHeader>
            <img className="header-logo" src={headerLogo} alt="" />
            {AccountInfos}
        </StyledHeader>
    )
}

export default Header
