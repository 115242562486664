import {ethers} from "ethers";
import RaptOriginZ from '../utils/ROZABI.json';
export const {ethereum} = window;
// const contractAddress = '0x2A3Ff2612231A0c918d92116676D1c6EFAd9aC0d'; // TESTNET
// export const teamWallet = '0xF096D4e0C02E4115aec303C656BA4b33880aB0e9'; // TESTNET

const contractAddress = '0x9f98ccebdb3138c0ebfbdc9b9b94e2683bdaf1fb'; // MAINNET
export const teamWallet = '0x092F5372de8DeF14552e3e4df92B9D6B5e195080'; // MAINNET

const burnAddress = "0x000000000000000000000000000000000000dEaD";

/// @dev function to return how many NFTs the user has:
export async function balanceOf(userWallet) {
    try {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const connectedContract = new ethers.Contract(contractAddress, RaptOriginZ.abi, provider);
            try {
                return await connectedContract.balanceOf(userWallet);
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log("Ethereum object doesn't exist !");
        }
    } catch (error) {
        console.log(error);
    }
}

/// @dev function to return which NFT the user owns according to the index (from balanceOf)
export async function tokenOfOwnerByIndex(userWallet, index) {
    try {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const connectedContract = new ethers.Contract(contractAddress, RaptOriginZ.abi, provider);
            try {
                return await connectedContract.tokenOfOwnerByIndex(userWallet, index);
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log("Ethereum object doesn't exist !");
        }
    } catch (error) {
        console.log(error);
    }
}

/// @dev function to return which NFT the user owns according to the index (from balanceOf)
export async function tokenURI(indexOfNFT) {
    try {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const connectedContract = new ethers.Contract(contractAddress, RaptOriginZ.abi, provider);
            try {
                return await connectedContract.tokenURI(indexOfNFT);
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log("Ethereum object doesn't exist !");
        }
    } catch (error) {
        console.log(error);
    }
}

/// @dev function to get json metadata to get image's url
export async function getJsonMetadata(ipfsURI) {
    try {
        ipfsURI = ipfsURI.replace('ipfs://', 'https://ipfs.io/ipfs/');
        let response = await fetch(ipfsURI);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

/// @dev function to return how many NFTs the user has
export function transferFrom (userWallet, tokenId) {
    return new Promise(async (resolve, reject) => {
        try {
            const {ethereum} = window;

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(contractAddress, RaptOriginZ.abi, signer);
                try {
                    const transaction = await connectedContract.transferFrom(userWallet, teamWallet, tokenId);
                    await transaction.wait();
                    resolve(transaction);
                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            } else {
                console.log("Ethereum object doesn't exist !");
                reject('Ethereum object doesn\'t exist !')
            }
        } catch (error) {
            console.log(error);
            reject(error);
        }
    })
}

/// @dev function to return how many NFTs the user has
export function burnToken (userWallet, tokenId) {
    return new Promise(async (resolve, reject) => {
        if (userWallet.toLowerCase() === teamWallet.toLowerCase()) {
            try {
                if (ethereum) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    const connectedContract = new ethers.Contract(contractAddress, RaptOriginZ.abi, signer);
                    try {
                        const transaction = await connectedContract.transferFrom(userWallet, burnAddress, tokenId);
                        await transaction.wait();
                        resolve(transaction);
                    } catch (error) {
                        console.log(error);
                        reject('Error, check console');
                    }
                } else {
                    console.log("Ethereum object doesn't exist !");
                    reject('Ethereum object doesn\'t exist !');
                }
            } catch (error) {
                console.log(error);
                reject(error);
            }
        }
    });
}
