import React, {useEffect, useState} from "react";
import styled from "styled-components"
import Title from "./Title";
import {burnToken, teamWallet, transferFrom} from "../../functions/interact_sc";
import closeIcon from "../../images/icons/close.svg"
import Button from "./Button";
import toast, {Toaster} from 'react-hot-toast';
import {select, setClaimed, upsert} from "../../functions/interact_bdd";

const StyledModal = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);

  .modal {
    position: relative;
    z-index: 4;
    width: 50%;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);

    .close-icon {
      position: absolute;
      width: 30px;
      top: 20px;
      right: 20px;
    }

    p {
      color: white;
      font-family: MontReg;
    }

    form {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
    }

    input {
      padding: 10px 20px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      border: none;

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
`

const FormModal = ({id, idOfKalao, closeModal, userWallet}) => {
    const [isConditionsAccepted, setConditionsAccepted] = useState(false)
    const [isProcessExplained, setProcessExplained] = useState(false);
    const [isSuccessTransfer, setSuccessTransfer] = useState(false);
    const [transactionHash, setTransactionHash] = useState('');
    const [formFields, setFormFields] = useState({
        name: "",
        lastName: "",
        street: "",
        postalCode: "",
        city: "",
        country: ""
    })

    const [disabled, setDisabled] = useState(false);

    const handleClaim = async () => {
        setDisabled(true);
        if (userWallet.toLowerCase() !== teamWallet.toLowerCase()) {
            toast.promise(upsert(formFields, id, idOfKalao), {
                loading: 'Save in database...',
                success: 'Succes, let\'s transfer',
                error: (err) => `Error: ${err}`
            }).then(() => {
                toast.promise(
                    transferFrom(userWallet, id),
                    {
                        loading: 'Transfer to team wallet before validation and burn...',
                        success: 'Success !',
                        error: (res) => `Error: ${res.message}`
                    }).then((res) => {
                    setDisabled(false);
                    setTransactionHash(res['hash']);
                    setSuccessTransfer(true);
                }).catch((err) => {
                    console.log(err);
                    setDisabled(false);
                })
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    const handleBurn = async () => {
        setDisabled(true);
        toast.promise(
            burnToken(userWallet, id),
            {
                loading: 'Processing...',
                success: 'Success !',
                error: (res) => `Error: ${res}`
            }).then(() => {
            setClaimed(id).then(() => {
                setDisabled(false);
                closeModal();
                window.location.reload();
            });
        }).catch(() => {
            setDisabled(false);
        })
    }

    const handleChange = (e, target) => {
        setFormFields({...formFields, [target]: e.target.value})
    }

    useEffect(() => {
        if (userWallet.toLowerCase() === teamWallet.toLowerCase()) {
            setConditionsAccepted(true);
            setProcessExplained(true);
            select(id).then(data => {
                console.log(data);
                setFormFields({
                    name: data.name,
                    lastName: data.lastName,
                    street: data.street,
                    postalCode: data.postalCode,
                    city: data.city,
                    country: data.country
                })
            });

        }
    }, [userWallet, id]);

    return (
        <StyledModal>
            <Toaster/>
            <div className="modal">
                <img className="close-icon" src={closeIcon} alt="" onClick={() => {
                    if (!disabled) {
                        closeModal()
                    }
                }}/>
                {!isProcessExplained ?
                    <>
                        <Title>How does the recovery of the token proceed ?</Title>
                        <div style={{marginTop: "5px", marginBottom: "15px"}}>
                            <p style={{marginTop: "10px"}}>
                                You will fill in a form with your delivery address. Be careful to put the right information!
                            </p>

                            <p style={{marginTop: "10px"}}>
                                After that, a Metamask window will open to transfer your NFT. It will not be burned right away but will be transferred to the team address which is the following :
                            </p>
                            <p style={{marginTop: "10px", fontWeight: "bold"}}>
                                {teamWallet}
                            </p>
                            <p style={{marginTop: "10px"}}>
                                Then the team will validate the request and identify which coin to send. The team will burn the NFT after sending the coin.
                            </p>
                            <p style={{marginTop: "10px", fontWeight: "bold"}}>
                                We invite you to read the following terms and conditions carefully
                            </p>

                        </div>
                        <Button click={() => {
                            setProcessExplained(true)
                        }}>I understand</Button>
                    </>
                    : !isConditionsAccepted ?
                        <>
                            <Title>General terms and conditions for the redeem process :</Title>
                            <div style={{marginTop: "5px", marginBottom: "15px"}}>
                                <p style={{marginTop: "10px"}}>
                                    - As soon as the redeem action is submitted to us and
                                    confirmed on our side, we will allow a maximum time period of
                                    30 days for the owner to receive the collectible in the selected
                                    place of delivery.
                                </p>

                                <p style={{marginTop: "10px"}}>
                                    - Once the wish to redeem from an owner has been received
                                    and confirmed by the team, there will be no possibility
                                    whatsoever to come back on this decision. The NFT will be
                                    burned and the coin delivered to its owner.
                                </p>

                                <p style={{marginTop: "10px"}}>
                                    - Delivery conditions: as soon as the redeem action is
                                    submitted and confirmed by the team, we will proceed to the
                                    delivery of the good via postal secure delivery. Once the
                                    collectible will be handed off to the delivery Company, the
                                    Novilunium Team will not be held responsible in case of delays
                                    or loss resulting in this delivery and this will have to be checked
                                    with the delivery Company in question.
                                </p>
                                <p  style={{marginTop: "10px", fontWeight: "bold"}}>
                                    If you have more than 10k $ worth of NFTs to be redeemed for coins, we highly recommend you to come and pick them up physically in Geneva.
                                </p>
                            </div>
                            <Button click={() => {
                                setConditionsAccepted(true)
                            }}>I accept</Button>
                        </>
                        :
                        isSuccessTransfer ?
                            <>
                                <Title>SUCCESS !</Title>
                                <div style={{marginTop: "5px", marginBottom: "15px"}}>
                                    <p style={{marginTop: "10px"}}>
                                        You can find your transaction here :
                                    </p>
                                    <p style={{marginTop: "10px", color: "white"}} onClick={() => {
                                        window.open('https://snowtrace.io/tx/' + transactionHash);
                                    }}>
                                        { transactionHash }
                                    </p>
                                </div>
                                <Button click={() => {
                                    closeModal()
                                    window.location.reload();
                                }}>Return to main menu</Button>
                            </>

                            :
                        <>
                            {
                                userWallet.toLowerCase() === teamWallet.toLowerCase() ?
                                    <>
                                        <Title tall>Verify user data for the Raptor Origin Z #{idOfKalao} coin</Title>
                                        <p>Blockchain ID : #{id}</p>
                                        <p>It is important to verify that the user has filled in his data correctly
                                            before burning the NFT for his coin.</p>
                                    </>
                                    :
                                    <>
                                        <Title tall>Claim your Raptor Origin Z #{idOfKalao} coin</Title>
                                        <p>Please fill the form to claim your coin and burn your NFT.</p>
                                    </>
                            }

                            <form>
                                <div className="row">
                                    <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                           name="name" placeholder="Indiana" value={formFields.name} onChange={(e) => {
                                        handleChange(e, "name")
                                    }}/>
                                    <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                           name="lastname" placeholder="Jones" value={formFields.lastName}
                                           onChange={(e) => {
                                               handleChange(e, "lastName")
                                           }}/>
                                </div>
                                <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                       name="street" placeholder="12 rue Dupont" value={formFields.street}
                                       onChange={(e) => {
                                           handleChange(e, "street")
                                       }}/>
                                <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                       name="postalCode" placeholder="75008" value={formFields.postalCode}
                                       onChange={(e) => {
                                           handleChange(e, "postalCode")
                                       }}/>
                                <div className="row">
                                    <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                           name="city" placeholder="Paris" value={formFields.city} onChange={(e) => {
                                        handleChange(e, "city")
                                    }}/>
                                    <input disabled={userWallet.toLowerCase() === teamWallet.toLowerCase()} type="text"
                                           name="country" placeholder="France" value={formFields.country}
                                           onChange={(e) => {
                                               handleChange(e, "country")
                                           }}/>
                                </div>
                            </form>
                            {
                                userWallet.toLowerCase() === teamWallet.toLowerCase() ?
                                    <Button click={() => {
                                        if (!disabled) {
                                            handleBurn().then();
                                        }
                                    }}>Burn token</Button>
                                    :
                                    <Button click={() => {
                                        if (!disabled) {
                                            if (formFields.name === "" ||
                                                formFields.lastName === "" ||
                                                formFields.street === "" ||
                                                formFields.postalCode === "" ||
                                                formFields.city === "" ||
                                                formFields.country === "") {
                                                toast.error('Please fill in all the fields.')
                                            } else {
                                                handleClaim().then()
                                            }
                                        }
                                    }}>Submit</Button>
                            }
                        </>


                }
            </div>
        </StyledModal>
    )
}

export default FormModal
