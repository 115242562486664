import { supabase } from '../supabaseClient'

export function upsert(contacts, nftId, kalaoId) {
    return new Promise(async (resolve, reject) => {
        try {
            const {data: insertData, error: insertError} = await supabase
                .from('Contact')
                .upsert({
                    nftId: nftId,
                    name: contacts.name,
                    lastName: contacts.lastName,
                    street: contacts.street,
                    city: contacts.city,
                    country: contacts.country,
                    postalCode: contacts.postalCode,
                    isClaimed: false,
                    kalaoId: kalaoId
                })
            if (insertError) {
                reject(insertError);
            }
            resolve(insertData);
        } catch (error) {
            reject(error);
        }
    });
}

export async function select(nftId) {
    try {
        const { data: selectData, error: selectError } = await supabase
        .from('Contact')
        .select(`*`)
        .eq('nftId', nftId)
        .single()

        if(selectError) {
            throw selectError
        }
        return selectData
    } catch (error) {
        throw error;
    }
}

export function setClaimed(nftId) {
    return new Promise(async (resolve, reject) => {
        try {
            const {data: insertData, error: insertError} = await supabase
                .from('Contact')
                .update({
                    isClaimed: true
                })
                .match({nftId: nftId})
            if (insertError) {
                reject(insertError);
            }
            resolve(insertData);
        } catch (error) {
            reject(error);
        }
    });

}
