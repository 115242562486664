const {ethereum} = window;

/// @dev function to connect wallet
export async function connectWallet() {
    try {
        if (!ethereum) {
            alert("Get Metamask please !");
            window.location.reload();
        }
        const accounts = await ethereum.request({method: "eth_requestAccounts"});
        console.log("Connected", accounts[0]);
        return accounts[0];
    } catch (error) {
        console.log(error);
    }
}

/// @dev function to check if a wallet is already connected
export async function checkIfWalletIsConnected() {
    if (!ethereum) {
        console.log("Make sure you have metamask !");
        return;
    } else {
        console.log("We have the ethereum object", ethereum);
    }
    const accounts = await ethereum.request({method: 'eth_accounts'});

    if (accounts.length !== 0) {
        return accounts[0];
    } else {
        console.log("No authorized account found");
    }
}
